import React, { useEffect } from 'react';
import './spin.scss';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useSpin from 'helper/hooks/use-spin';
import { useDispatch } from 'react-redux';

const tippyProps = {
  placement: 'top',
  appendTo:'parent',
  arrow: false,
  offset: [0, -20],
};

const Spin = (props) => {
  const spinBox = (start) => {
    let data = prizeList && [...prizeList.prize];

    let list =
      data &&
      data.slice(start, start + 4).map((data, index) => (
        <Tippy
          key={index}
          {...tippyProps}
          content={
            <p className="float-box">
              {data?.is_owned ? data?.fallback_item?.name : data?.name} (
              {data?.is_owned ? data?.fallback_item?.rarity_tooltip : data?.rarity_tooltip})
            </p>
          }
        >
          <div
            id={data?.order}
            key={index}
            style={{
              background: `url(${lang_json[data?.is_owned ? `rarity_${data?.fallback_item?.rarity.toLowerCase()}` : `rarity_${data?.rarity.toLowerCase()}`]}) center/100% 100% no-repeat`,
            }}
            className={`spinBox  ${data?.is_deleted || data?.is_claimed ? 'disabled' : ''}`}
          >
            {item.active.order === data?.order ? (
              <img
                className="target"
                src={`${process.env.REACT_APP_CDN_URL}target.png`}
                alt=""
                style={{ pointerEvents: 'none' }}
              />
            ) : data?.is_deleted ? (
              <div className="banned del" alt="" style={{ pointerEvents: 'none' }}>
                {/* <span>{lang_json.label_dihapus}</span>*/}
              </div>
            ) : data?.is_claimed ? (
              <div className="banned ban" alt="" style={{ pointerEvents: 'none' }}>
                {/* <span>{lang_json.label_diterima}</span> */}
              </div>
            ) : (
              ''
            )}
            <img
              className="items"
              src={data?.is_owned ? data?.fallback_item?.image : data?.image}
              alt=""
              style={{ pointerEvents: 'none' }}
            />
          </div>
        </Tippy>
      ));
    return list;
  };

  const spinBoxMid = (id) => {
    const dataPrize = prizeList && [...prizeList.prize];

    return (
      dataPrize && (
        <Tippy
          {...tippyProps}
          content={
            <p className="float-box">
              {dataPrize[id]?.is_owned ? dataPrize[id]?.fallback_item.name : dataPrize[id]?.name} (
              {dataPrize[id]?.is_owned ? dataPrize[id]?.fallback_item.rarity_tooltip : dataPrize[id]?.rarity_tooltip})
            </p>
          }
        >
          <div
            id={id + 1}
            style={{
              background: `url(${lang_json[dataPrize[id]?.is_owned ? `rarity_${dataPrize[id]?.fallback_item?.rarity?.toLowerCase()}` : `rarity_${dataPrize[id]?.rarity?.toLowerCase()}`]
                }) center/100% 100% no-repeat`,
            }}
            className={`spinBox  ${dataPrize[id]?.is_deleted || dataPrize[id]?.is_claimed ? 'disabled' : ''}`}
          >
            {item.active.order === dataPrize[id]?.order ? (
              <img
                className="target"
                src={`${process.env.REACT_APP_CDN_URL}target.png`}
                alt=""
                style={{ pointerEvents: 'none' }}
              />
            ) : dataPrize[id]?.is_deleted ? (
              <div className="banned del" alt="" style={{ pointerEvents: 'none' }}>
                {/* <span>{lang_json.label_dihapus}</span> */}
              </div>
            ) : dataPrize[id]?.is_claimed ? (
              <div className="banned ban" alt="" style={{ pointerEvents: 'none' }}>
                {/* received asset have text */}
                {/* <span>{lang_json.label_diterima}</span> */}
              </div>
            ) : (
              ''
            )}
            <img
              className="items"
              src={dataPrize[id]?.is_owned ? dataPrize[id]?.fallback_item?.image : dataPrize[id]?.image}
              alt=""
              style={{ pointerEvents: 'none' }}
            />
          </div>
        </Tippy>
      )
    );
  };

  const spinBoxCenter = (id) => {
    // const handleClickStop = () => {
    //   control.stop();
    // };

    return (
      <div className="centerBox">
        {!login.has_deleted && (
          <div onClick={() => toggle_modal('deletePrize', true)} className="buttonHapus">
            <p>{lang_json.delete_prize}</p>
          </div>
        )}

        <div
          onClick={() => (!onSpin ? postSpin() : '')}
          className={`buttonSpin ${!login.has_deleted || onSpin || login.spin_count >= login.max_spin ? 'disabled' : ''
            }`}
        >
          <span>
            <span>
              {login.price} <img src={`${process.env.REACT_APP_CDN_URL}icon_cp.png`} alt="" />
              <span>{lang_json.btn_spin} </span>
            </span>
          </span>
        </div>
        {/* <div>
          <button onClick={handleClickStop}>STOP</button>
        </div> */}
      </div>
    );
  };

  const barProgress = () => {
    let percent;
    percent = milestone.completion && (login.spin_count / milestone.completion.spin_count) * 70;
    return percent + '%';
  };

  const { login: check_login, postSpin } = props.actionsMain;
  const { toggle_modal } = props.actionsModal;
  const { lang_json, milestone, login, onSpin, prizeList, result, isSpin } = props.main;
  const { postMilestone, put_data } = props.actionsMain;

  const items = prizeList?.prize?.filter((prize) => !prize.is_claimed && !prize.is_deleted);
  const dispatch = useDispatch();

  const { item, control } = useSpin(items, {
    lastItem: result,
    intervalDuration: 100,
    lastItemDelay: 1000,
    onComplete: () => {
      dispatch(put_data('onSpin', false));
      dispatch(put_data('isSpin', false));
      toggle_modal('prize', true);
      check_login();
    },
  });

  useEffect(() => {
    if (!result) return;

    if (isSpin) control.start();

    // eslint-disable-next-line
  }, [result, isSpin]);

  return (
    <div className="Spin">
      <div className="completionContainer">
        <div style={{ backgroundColor: lang_json.bar_progress_color }} className="completion"></div>
        <div
          className="redBar"
          style={{ backgroundColor: lang_json.bar_progress_color_top, width: barProgress() }}
        ></div>
        <div
          style={{ background: `url(${lang_json.img_claim_gift}) center/contain no-repeat` }}
          onClick={() =>
            !onSpin && milestone.completion.is_open && !milestone.completion.is_claimed
              ? postMilestone(milestone.completion.id)
              : ''
          }
          className={`completionReward ${!milestone?.completion?.is_open ||
            milestone?.completion?.is_claimed ||
            login?.spin_count !== milestone?.completion?.spin_count
            ? 'disabled'
            : ''
            }`}
        >
          <p>{lang_json.completion_reward_text}</p>
        </div>
      </div>
      <div className="Spin-box">
        {spinBox(0)}
        {spinBoxMid(9)}
        {spinBoxCenter()}
        {spinBoxMid(4)}
        {spinBox(5) && spinBox(5).reverse()}
      </div>
      <div className="bottomButton">
        <div
          onClick={() => {
            !onSpin && put_data('selectedTab', 0) && toggle_modal('info', true);
          }}
          className="buttonInfo"
        >
          <p>{lang_json.btn_info}</p>
        </div>
        <div
          onClick={() => {
            !onSpin && put_data('selectedTab', 2) && toggle_modal('info', true);
          }}
          className="buttonHistory"
        >
          <p>{lang_json.btn_history}</p>
        </div>
      </div>
    </div>
  );
};

export default Spin;
