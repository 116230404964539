const modal_state = {
	msg: {
		title: false,
		message: false,
	},
	deletePrize: false,
	error: false,
	prize: false,
	history: false,
	info: false,
	consent:false
}

const modal = (state = modal_state, action) => {
	switch (action.type) {
		case "TOGGLE_MODAL":
			return {
				...state,
				[action.modal]: action.toggle
			};
		case "SET_MSG":
			return {
				...state,
				msg: {
					title: action.title,
					message: action.msg
				}
			};
		default:
			return state;
	}
};

export default modal;
