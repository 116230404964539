import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

// Component
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
import ModalCont from 'partial/Modal';

// Pages
import Home from 'pages/Home';
import { ErrorBoundary } from "@sentry/react"

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';
import * as authActions from 'redux/actions/auth';

import { setCssVariable } from 'helper';

class Main extends Component {
  componentDidMount() {
    const { set_token } = this.props.actionsAuth;
    set_token()
  }

  render() {
    const { lang_json } = this.props.main
    setCssVariable(lang_json)
  
    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <div id="Main" className="main-panel">
          <ErrorBoundary>
            <ViewportRestrict {...this.props} display={true} type="portrait" />
            <Loader loader={this.props.main.loader} />
            <div className="content-container">
              <Switch>
                <Route exact path="/" render={() => <Home {...this.props} />} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </ErrorBoundary>
          <ModalCont {...this.props} />
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    main: state.main,
    modal: state.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionsMain: bindActionCreators(mainActions, dispatch),
    actionsModal: bindActionCreators(modalActions, dispatch),
    actionsAuth: bindActionCreators(authActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
