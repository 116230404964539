import React, { Component } from 'react'
import './sidebar.scss'

export default class Sidebar extends Component {
    render() {
        const { login, lang_json } = this.props.main

        return (
            <div style={{
                background: `url(${lang_json.img_bg_left}) center/100% 100% no-repeat`,
            }} className="Sidebar">
                <img className='img-kv' src={lang_json.img_kv} alt='/' />
                <div className="event-title">
                    <img className="codm-logo" src={`${process.env.REACT_APP_CDN_URL}codm_logo.png`} alt="" />
                    <img src={lang_json.title_image} alt="" />
                </div>
                <div className="nickname">
                    <span>HI, {decodeURIComponent(login.username)}!</span>
                </div>
                <div className="balance">
                    <span>{lang_json.balance}: {login.balance} <img src={`https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/deletionluckydraw3/icon_cp.png`} alt="" /></span>
                </div>
                {/* <p style={{fontSize:200}}>{window.innerHeight}</p>
                <p style={{fontSize:200}}>{window.innerWidth}</p> */}
            </div>
        )
    }
}
