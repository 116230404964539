export const toggle_modal = (modal, toggle) => ({
    type: 'TOGGLE_MODAL',
    modal,
    toggle
})

export const set_msg = (title, msg) => ({
    type: 'SET_MSG',
    title,
    msg
})