import React, { Component } from 'react'
import { Modal } from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

export default class Error extends Component {
    render() {
        const { error, msg } = this.props.modal
        const { toggle_modal } = this.props.actionsModal
        const { lang_json } = this.props.main
        
        const shouldClose = (msg) => {
            const unCloseable = [
                'not_logged_in',
                'event_finished',
                'no_active_event',
                'maintenance',
                'PLEASE REFRESH YOUR BROWSER',
                'Please Refresh Your Browser!',
                'server_err'
            ]
            return !unCloseable.includes(msg)
        }
        const displayMsg = () => {
            if (lang_json[msg.message]) return lang_json[msg.message]
            return msg.message
        }

        return (
            <Modal
                container={this.props.mainRef}
                open={error}
                onClose={() => toggle_modal('error', false)}
                focusTrapped={false}
                center
                blockScroll={false}
                showCloseIcon={false}
                closeOnOverlayClick={false}
                classNames={{
                    overlay: 'overlay',
                    modal: 'modals modals--error'
                }}
            >
                <div className="errorTitle">
                    <span>{lang_json.popup_error_title || 'ERROR'}</span>
                </div>
                <div className="errorMessage">
                    <span>{displayMsg()}</span>
                </div>
                {shouldClose(msg.message) &&
                    <div className="buttonOption">
                        <div onClick={() => toggle_modal('error', false)} className="buttonBack">
                            <p>{lang_json.btn_tutup || 'CLOSE'}</p>
                        </div>
                    </div>
                }
            </Modal>
        )
    }
}
