import React, { Component } from 'react'
import { Modal } from 'react-responsive-modal';
import { Carousel } from 'react-responsive-carousel';

import { getLsObject, setLsObject } from 'helper';

import 'react-responsive-modal/styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: 0 };
    }
    
    historyList = () => {
        const { history, rarity_json } = this.props.main
        let list = history && history.map((data, index) =>
            <div key={index} className="itemWrapper">
                <div style={{
                    background: `url(${rarity_json[`rarity_${data?.rarity?.toLowerCase()}`]}) center/100% 100% no-repeat`,
                }} className="itemImage">
                    <img src={data.image} alt="" />
                </div>
                <div className="itemName">
                    <span>{data.name}</span>
                </div>
            </div>
        )
        return list
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.modal.info !== nextProps.modal.info) {
            this.setState({ activeTab: nextProps.main.selectedTab })
        }
    }

    render() {
        const { info } = this.props.modal
        const { toggle_modal } = this.props.actionsModal
        const { lang_json,login } = this.props.main
     
        const showTandaSeru = getLsObject(`ts-${login?.t_open_id}`)
        return (
            <Modal
                container={this.props.mainRef}
                open={info}
                onClose={() => toggle_modal('info', false)}
                focusTrapped={false}
                center
                blockScroll={false}
                showCloseIcon={true}
                closeOnOverlayClick={false}
                classNames={{
                    overlay: 'overlay',
                    modal: 'modals modals--info',
                    closeButton: 'close-button'
                }}
            >
                <div className='tabs-group'>
                    <div onClick={() => {
                        this.setState({ activeTab: 0 })
                        setLsObject(`ts-${login?.t_open_id}`, true)
                    }} className='tab' style={{
                        background: `url(${this.state.activeTab === 0 ? lang_json.img_tab_on : lang_json.img_tab_off}) center/100% 100% no-repeat`,
                    }}>
                        {!showTandaSeru && <div className='tandaseru' style={{
                            background: `url('https://cdngarenanow-a.akamaihd.net/webid/CODM/deletionluckydraw/tandaseru.png') center/100% 100% no-repeat`,
                        }} />}
                        {lang_json.popup_info_title}</div>
                    <div onClick={() => this.setState({ activeTab: 1 })} className='tab' style={{
                        background: `url(${this.state.activeTab === 1 ? lang_json.img_tab_on : lang_json.img_tab_off}) center/100% 100% no-repeat`,
                    }}>{lang_json.popup_tutorial_title}</div>
                    <div onClick={() => this.setState({ activeTab: 2 })} className='tab' style={{
                        background: `url(${this.state.activeTab === 2 ? lang_json.img_tab_on : lang_json.img_tab_off}) center/100% 100% no-repeat`,
                    }}>{lang_json.popup_history_title}</div>
                </div>
                {
                    this.state.activeTab === 0 ?
                        <div className='infoContainer'>
                            <div dangerouslySetInnerHTML={{ __html: lang_json.popup_event_info }} className="infoContent">
                            </div>
                        </div>
                        :
                        this.state.activeTab === 1 ?
                            <div className='infoContainer'>
                                <Carousel showIndicators={false} showThumbs={false} showStatus={false}>
                                    {lang_json.tutorial.map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <img alt='/' src={data.image} />
                                                <p className="legend">Legend 1</p>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            :
                            <div className='infoContainer'>
                                <div className="historyTitle">
                                    <span>{lang_json.popup_history_title}</span>
                                </div>
                                <div className="historyList">
                                    {this.historyList()}
                                </div>
                            </div>
                }
            </Modal >
        )
    }
}
