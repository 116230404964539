import { combineReducers } from "redux";
import main from "./main";
import modal from "./modal";
import auth from "./auth";

import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";

const rootPersistConfig = {
	key: "root",
	storage,
	whitelist: [""]
};

const authPersistConfig = {
	key: "auth",
	storage: storage,
	blacklist: ["err_code"]
};

const mainPersistConfig = {
	key: "main",
	storage: storage,
	// Ex: whitelist: ["login", "history"]
	// Only login and history will be persisted
	whitelist: [""]
};


const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, auth),
	main: persistReducer(mainPersistConfig, main),
	modal
});

export default persistReducer(rootPersistConfig, rootReducer);