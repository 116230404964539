import axios from 'axios';
import { toggle_modal, set_msg } from './modal';
import { err_msg } from '../../helper';
import { setAnalytics } from 'helper';

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
      return data;
    },
  ],
});

const common = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
      return data;
    },
  ],
});

const rarity = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/glb_codm_rarity/`,
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
      return data;
    },
  ],
});

export const toggle_loader = (data) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const put_data = (key, data) => ({
  type: 'PUT_DATA',
  key,
  data,
});

export const login = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));
    axios
      .get('api/check_login/')
      .then((resp) => {
        let lang = resp.data.region;
        document.body.setAttribute('data-lang', lang);
        dispatch(put_data('login', resp.data));
        dispatch(getSpin());
        dispatch(getMilestone());
        dispatch(getHistory());
        dispatch(get_translate(lang));
        setAnalytics(lang);
        if (resp.data.popup && resp.data.popup.has_taken_ation === false) {
          dispatch(toggle_modal('consent', true));
        }
      })
      .catch((err) => {
        dispatch(get_translate('EN'));
        dispatch(set_msg('Error', err_msg(err)));
        dispatch(toggle_modal('error', true));
        setAnalytics();
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_translate = (lang) => {
  return (dispatch) => {
    if (lang === 'MS' || lang === 'PH' || lang === 'SM' || lang === 'SMP' || lang === 'MSP') lang = 'EN';
    const cdnLang = lang.toUpperCase();

    const main_localize = cdn.get(`${cdnLang}.json?${Math.random() * 10000}`);
    const common_localize = common.get(`${cdnLang}.json?${Math.floor(Math.random() * 10000)}`);
    const rarity_localize = rarity.get(`data.json?${Math.floor(Math.random() * 10000)}`);

    axios.all([main_localize, common_localize, rarity_localize])
      .then((resp) => {
        dispatch(
          put_data('lang_json', {
            ...resp[0].data,
            ...resp[1].data,
          })
        );
        dispatch(put_data('rarity_json', resp[2].data));
      });
  };
};

export const select = (id) => {
  return (dispatch, getState) => {
    let { selected } = getState().main;

    if (selected.length < 2 && selected.indexOf(id) === -1) {
      selected.push(id);
    } else if (selected.indexOf(id) !== -1) {
      selected.splice(selected.indexOf(id), 1);
    }

    dispatch(put_data('selected', selected));
  };
};

export const postDelete = () => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    axios
      .post('api/delete/', { id: getState().main.selected })
      .then((resp) => {
        dispatch(toggle_modal('deletePrize', false));
        dispatch(login());
      })
      .catch((err) => {
        dispatch(set_msg('Error', err_msg(err)));
        dispatch(toggle_modal('error', true));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const getSpin = () => {
  return (dispatch) => {
    axios
      .get('api/spin/')
      .then((resp) => {
        dispatch(put_data('prizeList', resp.data));
      })
      .catch((err) => {
        dispatch(set_msg('Error', err_msg(err)));
        dispatch(toggle_modal('error', true));
      });
  };
};

export const postSpin = () => {
  return (dispatch, getState) => {
    dispatch(put_data('onSpin', true));
    dispatch(toggle_loader(true));
    axios
      .post('api/spin/')
      .then((resp) => {
        dispatch(put_data('result', resp.data));
        dispatch(put_data('isSpin', true));

        //     let blacklist = [];
        //     let prizeList = [...getState().main.prizeList.prize];
        //     prizeList.forEach((e) => {
        //       if (e.is_claimed || e.is_deleted) {
        //         blacklist.push(e.order);
        //       }
        //     });

        //     let i = 1;
        //     let step = 0;
        //     let stopAt = resp.data.order; //7
        //     let timeSpin = 250;

        //     let spin = (timeSpin) => {
        //       setTimeout(() => {
        //         if (i > 10) {
        //           i = 1;
        //         }
        //         while (blacklist.indexOf(i) !== -1) {
        //           i++;
        //           step++;
        //         }
        //         if (i === 10) {
        //           dispatch(put_data('target', 0));
        //         } else {
        //           dispatch(put_data('target', i));
        //         }

        //         i++;
        //         step++;

        //         if (step < 20 + stopAt) {
        //           // step >= ((20 + stopAt) - 1) ? timeSpin = 700 :
        //           //     step >= ((20 + stopAt) - 3) ? timeSpin = 450 :
        //           //         step >= ((20 + stopAt) - 6) ? timeSpin = 200 :
        //           //             step >= ((20 + stopAt) - 8) ? timeSpin = 150 :
        //           //                 step >= ((20 + stopAt) - 12) ? timeSpin = 75 : timeSpin = 50
        //           spin(timeSpin);
        //         } else {
        //           setTimeout(() => {
        //             dispatch(put_data('target', stopAt));
        //           }, 1000);
        //           setTimeout(() => {
        //             dispatch(toggle_modal('prize', true));
        //             dispatch(put_data('target', false));
        //             dispatch(login());
        //             dispatch(put_data('onSpin', false));
        //           }, 1200);
        //         }
        //       }, timeSpin);
        //     };

        //     spin(timeSpin);
      })
      .catch((err) => {
        dispatch(set_msg('Error', err_msg(err)));
        dispatch(toggle_modal('error', true));
        dispatch(put_data('onSpin', false));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const getMilestone = () => {
  return (dispatch) => {
    axios.get('api/milestone/').then((resp) => {
      dispatch(put_data('milestone', resp.data.data));
    });
  };
};

export const postMilestone = (id) => {
  return (dispatch) => {
    dispatch(toggle_loader(false));
    axios
      .post('api/milestone/', { id: id })
      .then((resp) => {
        dispatch(put_data('result', resp.data));

        dispatch(getMilestone());
        dispatch(toggle_modal('prize', true));
      })
      .catch((err) => {
        dispatch(set_msg('Error', err_msg(err)));
        dispatch(toggle_modal('error', true));
        dispatch(put_data('onSpin', false));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const getHistory = () => {
  return (dispatch) => {
    axios.get('api/history/').then((resp) => {
      dispatch(put_data('history', resp.data.data));
    });
  };
};

export const postConsent = (yay) => {
  return (dispatch, getState) => {
    dispatch(toggle_loader(true));
    axios
      .post('api/consent/', { accept: yay })
      .then((resp) => {
        dispatch(toggle_modal('consent', false));
        dispatch(login());
      })
      .catch((err) => {
        dispatch(set_msg('Error', err_msg(err)));
        dispatch(toggle_modal('error', true));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};
