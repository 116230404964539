import React, { Component } from "react";
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";

export default class Consent extends Component {
    render() {
        const { consent } = this.props.modal;
        const { toggle_modal } = this.props.actionsModal;
        const { lang_json } = this.props.main
        const { postConsent } = this.props.actionsMain;

        return (
            <Modal
                container={this.props.mainRef}
                open={consent}
                onClose={() => toggle_modal('consent', false)}
                focusTrapped={false}
                center
                blockScroll={false}
                showCloseIcon={false}
                closeOnOverlayClick={false}
                classNames={{
                    overlay: 'overlay',
                    modal: 'modals modals--consent'
                }}
            >
                <div className="consentTitle">
                  <span>{lang_json.popup_consent_title}</span>
                </div>
                <div className="consentContent" dangerouslySetInnerHTML={{__html:lang_json.popup_consent_content}} />
                <div className="buttonOption">
                    <div onClick={() => postConsent(true) } className="buttonBack">
                        <p>{lang_json.popup_consent_acc}</p>
                    </div>
                    <div onClick={() => postConsent(false) } className="buttonBack">
                        <p>{lang_json.popup_consent_decline}</p>
                    </div>
                </div>
            </Modal>
        );
    }
}
