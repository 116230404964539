import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const setSentry = () => {
	Sentry.setContext("additional", {
		localStorage: window.localStorage,
		loginUrl: window.location.href
	});
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		environment: process.env.NODE_ENV,
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0
	});
};

export const setupLayout = () => {
  // var resetTimer;
  // force single page application
  const setRootFontSize = () => {
    // designSafeArea = 1334px * 660px (landscape NOT include browser nav 90px)
    let designSafeWidth = 1334;
    let designSafeHeight = 660;
    let vmax = Math.max(window.innerHeight, window.innerWidth);
    let vmin = Math.min(window.innerHeight, window.innerWidth);
    let vmaxToFontSize = (vmax / designSafeWidth) * 100;
    let vminToFontSize = (vmin / designSafeHeight) * 100;
    let fontSize = Math.min(vmaxToFontSize, vminToFontSize);

    // set root font size and html viewport
    const elem = document.documentElement;
    elem.style.fontSize = `${fontSize}px`;
    elem.style.height = `${vmin}px`;
    elem.style.width = `${vmax}px`;
  };

  // const resetRootFontSize = () => {
  //   if (resetTimer) {
  //     clearTimeout(resetTimer);
  //   }
  //   resetTimer = setTimeout(function () {
  //     setRootFontSize();
  //   }, 200);
  // };
  setRootFontSize();
  // window.addEventListener('resize', resetRootFontSize);
};
