import React, { Component } from "react";
import "./home.scss";

// Component
import Sidebar from "./Sidebar";
import Spin from "./Spin";
import Milestone from "./Milestone";

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';

class Home extends Component {

    render() {

        return (
            <div className="Home">
                <Sidebar {...this.props} />
                <Spin {...this.props} />
                <div className="right-bar">
                    <Milestone {...this.props} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: state.main,
        modal: state.modal,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(mainActions, dispatch),
        actionsModal: bindActionCreators(modalActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
