import { useState } from 'react';

let isForceStop = false;

const useSpin = (
  items = [],
  {
    stepTotal = 25,
    intervalDuration = 100,
    lastItem,
    lastItemDelay = 1000,
    mode = 'forward',
    skipAnimation = false,
    onComplete = () => {},
  }
) => {
  const [isRunning, setIsRunning] = useState(false);
  const [activeItem, setActiveItem] = useState(false);

  function generateSteps() {
    const steps = [];

    if (items.length === 1) {
      steps.push(lastItem);
      return steps;
    }

    if (mode === 'random') {
      while (steps.length < stepTotal) {
        const lastStep = steps[steps.length - 1];
        const randomIndex = Math.floor(Math.random() * items.length);
        const nextStep = items[randomIndex];

        if (lastStep !== nextStep) {
          steps.push(nextStep);
        }
      }

      steps.push(lastItem);
    }

    if (mode === 'forward') {
      do {
        const lastStep = steps[steps.length - 1];
        const prevIndex = items.findIndex((item) => item === lastStep);
        const nextIndex = prevIndex + 1 === items.length ? 0 : prevIndex + 1;
        const nextStep = items[nextIndex];

        steps.push(nextStep);

        if (steps.length >= stepTotal) {
          if (nextStep.id === lastItem.id) {
            break;
          }
        }
      } while (steps.length < stepTotal + items.length);
    }

    return steps;
  }

  const sleep = (interval) => {
    return new Promise((resolve) => setTimeout(resolve, interval));
  };

  async function start() {
    function checkInterval(step) {
      const remainingStep = steps.length - 1 - step;

      if (slowStepTotal > remainingStep) {
        return intervalDuration + (slowStepTotal - remainingStep) * intervalDuration;
      }

      return intervalDuration;
    }

    setIsRunning(true);

    const steps = generateSteps();
    const slowStepTotal = Math.floor(steps.length * 0.2);

    for (let step = 0; step < steps.length; step++) {
      if (skipAnimation || isForceStop) {
        done();
        return;
      }

      const interval = checkInterval(step);
      const isLastStep = step === steps.length - 1;

      await sleep(interval);
      setActiveItem(steps[step]);

      if (isLastStep) {
        await sleep(lastItemDelay);
        done();
      }
    }
  }

  function done() {
    isForceStop = false;
    setActiveItem(false);
    setIsRunning(false);
    onComplete();
  }

  function stop() {
    isForceStop = true;
  }

  return {
    item: { active: activeItem },
    control: {
      start,
      stop,
      isRunning,
    },
  };
};

export default useSpin;
