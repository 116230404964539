import React, { Component } from "react";
import "./milestone.scss";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const tippyProps = {
  placement: "auto",
  arrow: false,
};

export default class Milestone extends Component {
  milestoneList = () => {
    const { milestone, lang_json, rarity_json, onSpin } = this.props.main;
    const { postMilestone } = this.props.actionsMain;
    let list =
      milestone &&
      milestone.milestones.slice(0).reverse().map((data, index) => (
        <div
          key={index}
          className='itemWrapper'
        >
          <div className='spinCount'>
            <span>{data.spin_count} {lang_json.milestone_spin_text}</span>
          </div>
          <Tippy
            {...tippyProps}
            content={<p className='float-box'>{data.name} ({data.rarity_tooltip})</p>}
          >
            <div style={{
              background: `url(${rarity_json[`rarity_${data.rarity.toLowerCase()}`]}) center/100% 100% no-repeat`,
            }} className='purpleCrate'>
              <img
                // src={`${process.env.REACT_APP_CDN_REWARD_URL}${data.image}`}
                src={data.image}
                alt=''
              />
            </div>
          </Tippy>
          <div style={{
            background: `url(${lang_json.img_btn_klaim}) center/100% 100% no-repeat`,
          }} onClick={() =>
            !onSpin && data.is_open && !data.is_claimed ? postMilestone(data.id) : ""
          } className={`${data.is_open && !data.is_claimed ? "" : "disabled"
            } buttonClaim`}>
            <p>{lang_json.btn_claim}</p>
            {/* <img
              onClick={() =>
                data.is_open && !data.is_claimed ? postMilestone(data.id) : ""
              }
              src={`https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/deletionluckydraw3/btn_klaim.png`}
              alt=''
            /> */}
          </div>
        </div>
      ));
    return list;
  };

  // milestonePoint = () => {
  //     const { milestone } = this.props.main
  //     let list = milestone && milestone.map((data, index) =>
  //         <div key={index} className="pointer">
  //             <img src={`${process.env.REACT_APP_CDN_URL}icon_milestone_color.png`} alt="" />
  //         </div>
  //     )
  //     return list
  // }

  barProgress = () => {
    let { milestone, login } = this.props.main;
    if (!milestone.milestones) return '0%'

    let percent;
    const fullProgress = 95

    if (login?.spin_count >= milestone?.milestones[2]?.spin_count) {
      percent = fullProgress
    } else if ( login?.spin_count <= milestone?.milestones[0]?.spin_count) {
      percent =  (login.spin_count / milestone?.milestones[0]?.spin_count * 35)
    } else if (login.spin_count <= milestone?.milestones[1]?.spin_count) {
      percent = (login.spin_count - milestone?.milestones[0]?.spin_count) / (milestone?.milestones[1]?.spin_count - milestone?.milestones[0]?.spin_count) * (65.9 - 35) + 35
    } else {
      percent = (login.spin_count - milestone?.milestones[1]?.spin_count) / (milestone?.milestones[2]?.spin_count - milestone?.milestones[1]?.spin_count) * (91.5 - 65.9) + 65.9
    }

    return percent + "%";
  };

  render() {
    const { lang_json } = this.props.main;

    return (
      <div className='Milestone'>
        <div style={{
          background: `url(${lang_json.img_milestone}) center/100% 100% no-repeat`,
        }} className='bar'>
          <div className='redBar' style={{ height: this.barProgress() }}></div>
          {/* <div className="milestonePoint">
                        {this.milestonePoint()}
                    </div> */}
        </div>
        <div className='itemMilestone'>{this.milestoneList()}</div>
      </div>
    );
  }
}
