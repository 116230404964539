import axios from "axios";
import {
    err_msg,
    getAllUrlParam,
    removeParamUrl
} from "helper";
import { get_translate, login } from "./main";
import { set_msg, toggle_modal } from "./modal";
const ls = window.localStorage;

export const set_param = (data) => ({
    type: "SET_PARAM",
    data
});

export const set_token = () => {
    return (dispatch, getState) => {
        const { auth } = getState()
        const params = getAllUrlParam();
        let param = Object.keys(params).length === 0 ? auth?.param : params;

        if (param?.access_token || param?.gop_access_token) {
            dispatch(getJWT(param)).then(() => {
                /** Call api checklogin or info */
                dispatch(login())
                dispatch(set_param(param));
            }).catch((err) => {
                /** Handle error , show modal or toast error */
                dispatch(get_translate('EN'));
                dispatch(set_msg('Error', 'not_logged_in'));
                dispatch(toggle_modal('error', true));
            });
        } else {
            /** Handle error , show modal or toast error */
            dispatch(get_translate('EN'));
            dispatch(set_msg('Error', err_msg('not_logged_in')));
            dispatch(toggle_modal('error', true));
            return;

        }


    };
};


export const getJWT = (param) => {
    return () => {
        return axios
            .get(`oauth/callback/`, { params: param })
            .then((resp) => {
                removeParamUrl();
                ls.setItem("token", resp.data.token);
                axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = resp.data.token;
            })
    };
};
