import React, { Component } from "react";
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";

export default class Delete extends Component {
  
  prizeList = () => {
    const { prizeList, selected, rarity_json } = this.props.main;
    const { select } = this.props.actionsMain;
    let list =
      prizeList &&
      prizeList.prize.map((data, index) => {
        if (data.is_grandprize) return null
        return (
          <div key={index} className="itemContainer">
            <div
              style={{
                background: `url(${rarity_json[`rarity_${data?.rarity?.toLowerCase()}`]}) center/100% 100% no-repeat`,
              }}
              onClick={() => select(data.id)}
              
              className='itemWrapper'
            >
              <div className='checkBox'>
                {selected.indexOf(data.id) !== -1 ? (
                  <img
                    className="tick"
                    src='https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/deletionluckydraw/icon_tick.png'
                    alt=''
                  />
                ) : (
                  ""
                )}
              </div>
              <img
                src={data.image}
                alt=''
              />
            </div>
            <span className="itemName">{data.name}</span>
          </div>
        );
      });
    return list;
  };

  render() {
    const { deletePrize } = this.props.modal;
    const { toggle_modal } = this.props.actionsModal;
    const { postDelete } = this.props.actionsMain;
    const { lang_json, selected } = this.props.main
    
    return (
      <Modal
        container={this.props.mainRef}
        open={deletePrize}
        onClose={() => toggle_modal("deletePrize", false)}
        focusTrapped={false}
        center
        blockScroll={false}
        showCloseIcon={true}
        closeOnOverlayClick={false}
        classNames={{
          overlay: "overlay",
          modal: "modals modals--deletePrize",
          closeButton: 'close-button'
        }}
      >
        <div className='modalTitle'>
          <span>
            {lang_json.popup_hapus_title}
          </span>
        </div>
        <div className='prizeList'>{this.prizeList()}</div>
        <div className='buttonOption'>

          <div onClick={() => selected.length === 2 && postDelete()} className={`buttonConfirmation ${selected.length !== 2 && 'disabled'}`}>
            <p>{lang_json.btn_konfirmasi}</p>
          </div>
        </div>
      </Modal>
    );
  }
}
