import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import axios from "axios";

import * as defaults from "helper/default";
// import * as serviceWorker from './serviceWorker'; // UNCOMMENT FOR PWA

// COMPONENT & OTHER
import "assets/scss/index.scss";
import Main from "base/Main";

// REDUX
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "redux/reducers";

// REDUX PERSIST
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// SET REDUX STORE
const store = createStore(rootReducer, applyMiddleware(thunk));
const persistor = persistStore(store);


document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
});


// SET DEFAULT AXIOS
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
// SET LAYOUT KEEP ASPECT RATIO VIEWPORT
defaults.setupLayout()

if (process.env.REACT_APP_USE_TOKEN === "true") {
  var token = window.localStorage.getItem("token");
  axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
}

// SET AXIOS INTERCEPTOR
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const config = {
      method: error?.response?.config?.method,
      status_code: error?.response?.status,
      endpoint: error?.response?.request?.responseURL,
    };

    const { method, status_code, endpoint } = config;

    // // Check status code, if below 5xx, don't send. Send report only 5xx error
    if (status_code > 499) {
      // Will send data like 'https://www.FE.com/--> https://www.BE.com/api/error/__500__POST
      ReactGA.event(
        {
          category: endpoint,
          action: method,
          label: status_code.toString(),
          nonInteraction: true,
        },
        ["error_report"]
      );
    }

    return Promise.reject(error);
  }
);

defaults.setSentry();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Main />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.unregister(); // UNCOMMENT TO REMOVE PWA || COMMENT THE REGISTER
// serviceWorker.register(); // UNCOMMENT FOR PWA
