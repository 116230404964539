import React, { Component } from "react";
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";

export default class Prize extends Component {
  render() {
    const { prize } = this.props.modal;
    const { result, lang_json, rarity_json } = this.props.main;
    const { toggle_modal } = this.props.actionsModal;

    const isFallback = result?.is_fallback;
    const prizeRarity = isFallback ? result?.fallback_item?.rarity : result?.rarity;
    const prizeImg = isFallback ? result?.fallback_item?.image : result?.image;
    const prizeName = isFallback ? result?.fallback_item?.name : result?.name;

    return (
      <Modal
        container={this.props.mainRef}
        open={prize}
        onClose={() => toggle_modal("prize", false)}
        focusTrapped={false}
        center
        blockScroll={false}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{
          overlay: "overlay",
          modal: "modals modals--prize",
        }}
      >
        <div className='prizeTitle'>
          <span>{lang_json.popup_prize_title_1}</span>
          <span>
            {
              isFallback ?
                lang_json?.popup_prize_fallback?.replace('{grandprize_name}',result?.name).replace('{fallback_name}',result?.fallback_item?.name) : 
                lang_json?.popup_prize_title_2
            }
          </span>
        </div>
        <div className='prizeImage' style={{
          background: `url(${rarity_json[`rarity_${ prizeRarity?.toLowerCase()}`]}) center/100% 100% no-repeat`,
        }}>
          <img
            src={prizeImg}
            alt=''
          />
        </div>
        <div className='prizeName'>
          <span>{prizeName}</span>
        </div>
        <div className='buttonOption'>
          <div onClick={() => toggle_modal("prize", false)} className='buttonBack'>
            <p>{lang_json.btn_tutup}</p>
          </div>
        </div>
      </Modal>
    );
  }
}
