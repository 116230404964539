import React, { Component } from 'react'
import './modal.scss'

// Components
import Delete from './components/Delete'
import Error from './components/Error'
import Prize from './components/Prize'
import Info from './components/Info'
import Consent from './components/Consent'

export default class ModalCont extends Component {
    render() {
        const mainRef = document.getElementById('Main');

        return (
            <>
                <Delete mainRef={mainRef} {...this.props} />
                <Error mainRef={mainRef} {...this.props} />
                <Prize mainRef={mainRef} {...this.props} />
                <Info mainRef={mainRef} {...this.props} />
                <Consent mainRef={mainRef} {...this.props} />
            </>
        )
    }
}
