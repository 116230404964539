import ReactGA from "react-ga4";

export const getAllUrlParam = (url = window.location) => {
    let params = {};

    new URL(url).searchParams.forEach(function (val, key) {
        if (params[key] !== undefined) {
            if (!Array.isArray(params[key])) {
                params[key] = [params[key]];
            }
            params[key].push(val);
        } else {
            params[key] = val;
        }
    });

    return params;
};


export const removeParamUrl = () => {
    const location = window.location;
    window.history.pushState({}, document.title, location.origin + location.pathname);
}

export const err_msg = (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 500:
                return "Please Refresh Your Browser!";
            default:
                return err.response.data.msg || err.response.data.message;
        }
    } else if (err.request) {
        return "Please Refresh Your Browser!";
    } else {
        return "Please Refresh Your Browser!";
    }
}

export const setCssVariable = (assets) => {
    const root = document.querySelector(':root');
    root.style.setProperty('--img_bg', `url(${assets.img_bg})`);
    root.style.setProperty('--bg_popup', `url(${assets.img_bg_popup})`);
    root.style.setProperty('--box_epic', `url(${assets.box_epic})`);
    root.style.setProperty('--box_rare', `url(${assets.box_rare})`);
    root.style.setProperty('--box_legendary', `url(${assets.box_legendary})`);
    root.style.setProperty('--img_label_received', `url(${assets.img_label_received})`);
    root.style.setProperty('--img_label_delete', `url(${assets.img_label_delete})`);
    root.style.setProperty('--img_btn_spin', `url(${assets.img_btn_spin})`);
    root.style.setProperty('--img_btn_delete', `url(${assets.img_btn_delete})`);
    root.style.setProperty('--img_btn_default', `url(${assets.img_btn_default})`);
    root.style.setProperty('--img_btn_close_popup', `url(${assets.img_btn_close_popup})`);
    root.style.setProperty('--img_bg_check', `url(${assets.img_bg_check})`);
    root.style.setProperty('--img_btn_klaim', `url(${assets.img_btn_klaim})`);
    root.style.setProperty('--button_text_color', assets.button_text_color);
    root.style.setProperty('--bar_right_fill_color', assets.bar_right_fill_color);
    root.style.setProperty('--bg_popup', `url(${assets.img_bg_popup})`);
    root.style.setProperty('--bg_popup', `url(${assets.img_bg_popup})`);
};

export const setAnalytics = (region) => {
    const regionGA = process.env[`REACT_APP_GA_${region}`] ?? process.env.REACT_APP_GA
	ReactGA.initialize([{ trackingId: regionGA }]);
	ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
	ReactGA.event({
		category: window.location.origin + window.location.pathname,
		action: window.releases
	});
};

export const setLsObject = (key, values) => {
	window.localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = (key) => {
	return JSON.parse(window.localStorage.getItem(key));
};

