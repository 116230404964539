const auth_state = {
      param: false,
      callback: false,
};

const auth = (state = auth_state, action) => {
      switch (action.type) {
            case 'SET_PARAM':
                  return { ...state, param: action.data };
            case 'SET_CALLBACK':
                  return { ...state, callback: action.data };
            default:
                  return state;
      }
};

export default auth;
